export const checksums = {
  "pages": "OZa6HeTpcE",
  "articles": "6SNzTD5VwD",
  "categories": "QNOt47KSiD",
  "pageSlugs": "eEhAq9QZOJ",
  "blogSlugs": "ckHy0N5uRp"
}

export const tables = {
  "pages": "_content_pages",
  "articles": "_content_articles",
  "categories": "_content_categories",
  "pageSlugs": "_content_pageSlugs",
  "blogSlugs": "_content_blogSlugs",
  "info": "_content_info"
}

export default {
  "pages": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "block": "json"
    }
  },
  "articles": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "block": "json"
    }
  },
  "categories": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json"
    }
  },
  "pageSlugs": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json"
    }
  },
  "blogSlugs": {
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json"
    }
  },
  "info": {
    "fields": {}
  }
}